import React from 'react';
import { Modules, P60ModulePage as P60ModulePageType } from '~/lib/data-contract';
import { DynamicBlockList } from '~/templates/blocks';

type P60ModulePageProps = P60ModulePageType;

export const P60ModulePage = (props: P60ModulePageProps) => {
    return (
        <>
            <DynamicBlockList elements={props.pageElements as Modules[]} />
            <DynamicBlockList elements={props.bottomPageElements as Modules[]} />
        </>
    );
};
